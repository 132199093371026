<template>
  <div class="form-group template-div">
    <select
      class="form-control template-style"
      :disabled="!loaded"
      @change="changeTemplate"
    >
      <option value>{{ $t('generic-str.lbl-choose-template') }}</option>
      <!-- Escolher Template -->
      <option
        v-for="template in templates"
        :key="template.id"
        :value="template.body"
      >
        {{ template.name }}
      </option>
    </select>
    <div v-if="!loaded" class="preloader pl-xxs pls-primary">
      <svg class="pl-circular" viewBox="25 25 50 50">
        <circle class="plc-path" cx="50" cy="50" r="20" />
      </svg>
    </div>
    <router-link
      to="templates"
      class="btn btn-yup-purple btn-icon-add"
      tag="button"
      ><span class="material-icons"> add </span></router-link
    >
  </div>
</template>

<script>
import SmsService from '@/services/sms.service';

export default {
  data() {
    return {
      loaded: false,
      templates: {},
    };
  },
  mounted() {
    this.fetchTemplates();
  },
  methods: {
    fetchTemplates() {
      SmsService.getTemplates({}).then(
        (response) => {
          this.loaded = true;
          this.templates = response.data;
        },
        (error) => {
          this.content = error;
        },
      );
    },
    changeTemplate(e) {
      this.$emit('input', e.target.value);
    },
  },
};
</script>

<style scoped lang="scss">
.card-body {
  min-height: 300px;
}
.preloader {
  position: absolute;
  top: 5px;
  right: 35px;
}
/* Rebranding */
.template-style {
  height: 38px;
  font-size: 0.8rem;
  width: 100%;
}
.btn-icon-add {
  padding: 10px;
  height: 38px;
  width: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.template-div {
  display: flex;
  width: inherit;
  gap: 10px;
}
@media (min-width: 768px) {
  .template-style {
    width: 320px;
  }
}
</style>
